import {combineReducers} from "redux";
import authReducers from "./actions/authActions/authReducer";
import nodeTreeReducers from "./actions/treeActions/treeReducer";
import alarmsReducer from "./actions/alarmsActions/alarmsReducers";
import configReducer from "./actions/configActions/configReducers";
import usersReducer from "./actions/usersActions/usersReducers";
import hardwareReducer from "./actions/hardwareActions/hardwareReducers";
import ThemeOptions from "./actions/themeActions";
import mediaReducer from "./actions/mediaActions/mediaReducers";
import analyticsReducer from "./actions/analyticsActions/analyticsReducer";
import eventsReducer from "./actions/eventsActions/eventsReducers";
import errorLoggingReducer from "./actions/errorLoggingActions/errorLoggingReducer";
import tagsReducer from "./actions/tagsActions/tagsReducer";
import dateRange from "../main/platform/dashboards/components/dateRange/duck";
import dateGroup from "../main/platform/dashboards/components/dateGroup/duck";
import {pendingAPIReducer, pendingAPIReducerPath} from "../main/platform/dashboards/pendings/ducks";
import {notificationAPIReducer, notificationAPIReducerPath} from "../main/platform/dashboards/notifications/ducks";
import {analyticsAPIReducer, analyticsAPIReducerPath} from "../main/platform/dashboards/overview/ducks";
import {eventsAPIReducer, eventsAPIReducerPath, causesAPIReducer, causesAPIReducerPath} from
        "../main/platform/dashboards/overview/eventView/ducks";
import {versionAPIReducer, versionAPIReducerPath} from "../main/ducks";
import {maintenanceEventAPIReducer, maintenanceEventAPIReducerPath} from "../main/platform/dashboards/pendings/ducks/maintenanceEvent";
import {alarmsAPIReducer, alarmsAPIReducerPath} from "../main/platform/dashboards/alarms/ducks";
import {reportsAPIReducer, reportsAPIReducerPath} from "../main/platform/dashboards/reports/ducks";
import reportsDownload from "../main/platform/dashboards/reports/ducks/downloadDuck";
import dataDownload from "../main/platform/dashboards/configuration/ducks/downloadData";
import {hardwareAPIReducer, hardwareAPIReducerPath} from "../main/platform/dashboards/reports/ducks/hardwareDuck";
import {
    diagnosticsAPIReducer, diagnosticsAPIReducerPath,
    evidenceAPIReducer, evidenceAPIReducerPath,
    featuresAPIReducer, featuresAPIReducerPath,
    findingsAPIReducer, findingsAPIReducerPath,
    problemsAPIReducer, problemsAPIReducerPath,
} from "../main/platform/dashboards/analysis/ducks";
import {usersAPIReducer, usersAPIReducerPath} from "../main/platform/dashboards/User/ducks";
import {configurationAPIReducer, configurationAPIReducerPath} from "../main/platform/dashboards/configuration/ducks";
import {messagesAPIReducer, messagesAPIReducerPath} from "../main/platform/dashboards/inbound_messages/ducks";
import { bearingsAPIReducer, bearingsAPIReducerPath } from "../main/platform/dashboards/configuration/ducks/bearingsData";
import { dashboardsAPIReducer, dashboardsAPIReducerPath } from "../main/platform/dashboards/components/dashboard/ducks";
import { internationalizationAPIReducer, internationalizationAPIReducerPath } from "../main/platform/ducks";
import { mediaAPIReducer, mediaAPIReducerPath } from "../main/platform/dashboards/overview/ducks/mediaDuck";
import { orientationAPIReducer, orientationAPIReducerPath } from "../main/platform/dashboards/analysis/ducks/orientationDuck";
import { bestPracticeAPIReducer, bestPracticeAPIReducerPath } from "../main/platform/dashboards/analysis/ducks/bestPracticeDuck";
import { managementNodesAPIReducer, managementNodesAPIReducerPath } from "../main/platform/dashboards/configuration/ducks/managementNodes";
import { installationAPIReducer, installationAPIReducerPath } from "../main/platform/dashboards/configuration/ducks/wiverInstallation";
import { exportAPIReducer, exportAPIReducerPath } from "../main/platform/dashboards/diagnostics/ducks/export";


const appReducer = combineReducers({
    authReducers,
    nodeTreeReducers,
    alarmsReducer,
    configReducer,
    usersReducer,
    hardwareReducer,
    ThemeOptions,
    mediaReducer,
    analyticsReducer,
    eventsReducer,
    errorLoggingReducer,
    tagsReducer,
    dateRange,
    dateGroup,
    [pendingAPIReducerPath]:pendingAPIReducer,
    [notificationAPIReducerPath]:notificationAPIReducer,
    [eventsAPIReducerPath]:eventsAPIReducer,
    [causesAPIReducerPath]:causesAPIReducer,
    [analyticsAPIReducerPath]: analyticsAPIReducer,
    [versionAPIReducerPath]: versionAPIReducer,
    [maintenanceEventAPIReducerPath]: maintenanceEventAPIReducer,
    [alarmsAPIReducerPath]: alarmsAPIReducer,
    [reportsAPIReducerPath]:reportsAPIReducer,
    reportsDownload,
    dataDownload,
    [hardwareAPIReducerPath]:hardwareAPIReducer,
    [problemsAPIReducerPath]: problemsAPIReducer,
    [findingsAPIReducerPath]: findingsAPIReducer,
    [evidenceAPIReducerPath]: evidenceAPIReducer,
    [diagnosticsAPIReducerPath]: diagnosticsAPIReducer,
    [usersAPIReducerPath]: usersAPIReducer,
    [featuresAPIReducerPath]: featuresAPIReducer,
    [configurationAPIReducerPath]: configurationAPIReducer,
    [messagesAPIReducerPath]: messagesAPIReducer,
    [bearingsAPIReducerPath]: bearingsAPIReducer,
    [dashboardsAPIReducerPath]: dashboardsAPIReducer,
    [internationalizationAPIReducerPath]: internationalizationAPIReducer,
    [mediaAPIReducerPath]: mediaAPIReducer,
    [orientationAPIReducerPath]: orientationAPIReducer,
    [bestPracticeAPIReducerPath]: bestPracticeAPIReducer,
    [managementNodesAPIReducerPath]: managementNodesAPIReducer,
    [installationAPIReducerPath]: installationAPIReducer,
    [exportAPIReducerPath]: exportAPIReducer,
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'AUTH_LOGOUT') {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;